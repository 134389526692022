.appt-book {
  .location-link .appt-book-link,
  #appt-book-book-submit {
    padding-top: 8px;
    font-size: 23px !important;
  }
  .appointment-select {
    .appointments-container {
      .book-appt-container {
        overflow: visible;
        .registration__email-list,
        .registration__sms-list,
        .registration__privacy-policy-list {
          width: 100%;
          display: block;
          float: none;
          font-size: 12px;
          line-height: 1.5em;
          margin-left: 1px;
        }
        .mobile-phone-fieldset {
          .appt-book-mobile-prefix {
            width: 22% !important;
          }
          .appt-book-mobile {
            width: 78% !important;
          }
        }
      }
      .error {
        a {
          color: $color-error;
        }
      }
    }
  }
  .service-select {
    .service {
      .selection-bar {
        .selection-selector {
          font-size: 9px;
          letter-spacing: 1.5px;
        }
      }
    }
  }
}

.counters {
  .appt-book-page-header-content {
    width: 60%;
    max-width: 490px;
  }
}

#appointment-cancellation {
  .button-container {
    margin-bottom: 30px;
  }
}
